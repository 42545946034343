@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --header-outer-height: 110px;
  --header-inner-height: 70px;
  --header-height-difference: calc(
    var(--header-outer-height) - var(--header-inner-height)
  );
  --header-bg: #fff;
}

body {
  font-family: "DM Sans", sans-serif;
  background-color: #f2f5f7;
  line-height: 1.5;
  position: relative;
}

.responsive-wrapper {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/* Sticky header */
.header-outer {
  /* Make it stick */
  height: var(--header-outer-height);
  position: sticky;
  top: calc(
    var(--header-height-difference) * -1
  ); /* Multiply by -1 to get a negative value */
  display: flex;
  align-items: center;

  /* Other */
  background-color: var(--header-bg);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.header-inner {
  /* Make it stick */
  height: var(--header-inner-height);
  position: sticky;
  top: 0;

  /* Other */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Styling of other elements */
.header-logo img {
  display: block;
  height: calc(var(--header-inner-height) - 40px);
}

.header-navigation {
  display: flex;
  flex-wrap: wrap;
}

.header-navigation a,
.header-navigation button {
  font-size: 1.125rem;
  color: inherit;
  margin-left: 1.75rem;
  position: relative;
  font-weight: 500;
}

.header-navigation a {
  display: none;
  font-size: 1.125rem;
  color: inherit;
  text-decoration: none;
}

.header-navigation button {
  border: 0;
  background-color: transparent;
  padding: 0;
}

.header-navigation a:hover:after,
.header-navigation button:hover:after {
  transform: scalex(1);
}

.header-navigation a:after,
.header-navigation button:after {
  transition: 0.25s ease;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentcolor;
  transform: scalex(0);
  position: absolute;
  bottom: -2px;
  left: 0;
}

.main {
  margin-top: 3rem;
}

@media (min-width: 800px) {
  .header-navigation a {
    display: inline-block;
  }

  .header-navigation button {
    display: none;
  }
}

.bn632-hover {
  width: auto;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  // moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  // moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn25 {
  margin-left: 0%;
  background-image: linear-gradient(
    to right,
    #29323c,
    #485563,
    #2b5876,
    #4e4376
  );
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.bn632-hover.bn26 {
  margin-right: 20%;
  background-image: linear-gradient(
    to right,
    #29323c,
    #485563,
    #2b5876,
    #4e4376
  );
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.center {
  margin-top: 1%;
  margin-right: auto;
}

// --------------------

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb {
  background: #6e93f7;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4070f4;
}
.container {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 30px;
  max-width: 800px !important;
  width: 100% !important;
  border-radius: 12px;
  padding: 30px;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-padding: 30px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}
.container .card {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 12px;
  background: #fff;
  scroll-snap-align: start;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}
.card .image {
  height: 50%;
  width: 50%;
  padding: 4px;
}
.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card h2 {
  margin-top: 25px;
  color: #333;
  font-size: 22px;
  font-weight: 600;
}
.card p {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  text-align: center;
}
